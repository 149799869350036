import { Module, VuexModule, getModule, Mutation, Action } from "vuex-module-decorators";
import { ApiErrorResponse, ApiSistemaPagos, AxiosHttpClient, getUrl, ApiList, ApiBancos } from "apd.apiconnectors";
import { Kiosko, CorteCaja } from "apd.models";
import store from "@/store";
import errorSnackbar from "@/store/modules/errorSnackbar";
import { Banorte } from "apd.models";

@Module({
  namespaced: true,
  name: "SelectoresModule",
  store,
  dynamic: true,
})
class SelectoresModule extends VuexModule {
  public loading = false;
  public cajeros: Kiosko[] = [];
  public cortes: CorteCaja[] = [];
  public afiliacionesBanorte: Banorte.Payworks2.Afiliacion[] = [];
  public afiliacionesConciliablesBanorte: Banorte.Payworks2.Afiliacion[] = [];

  public get NombreCajero() {
    return (id: string): string => {
      if (this.cajeros.length > 0) {
        const cajero = this.cajeros.find((c) => c.id == id);

        if (cajero) {
          return cajero.nombreReportes;
        }

        return "";
      } else {
        return "";
      }
    };
  }

  @Mutation
  public SET_LOADING(value: boolean) {
    this.loading = value;
  }
  @Mutation
  public SET_CAJEROS(value: Kiosko[]) {
    this.cajeros = value;
  }
  @Mutation
  public SET_CORTES(value: CorteCaja[]) {
    this.cortes = value;
  }
  @Mutation
  public SET_AFILIACIONES_BANORTE(value: Banorte.Payworks2.Afiliacion[]) {
    this.afiliacionesBanorte = value;
  }
  @Mutation
  public SET_AFILIACIONES_CONCILIABLES_BANORTE(value: Banorte.Payworks2.Afiliacion[]) {
    this.afiliacionesConciliablesBanorte = value;
  }

  @Action({ rawError: true })
  public async LoadCajeros() {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(client);

    try {
      this.SET_LOADING(true);
      const cajeros = (await apiSistemaPagos.GetCajeros()) as Kiosko[];
      this.SET_CAJEROS(cajeros);
    } catch (e) {
      //const err = e as ApiErrorResponse;
      //TODO: Manejar errores
      //console.log(err);

      this.SET_CAJEROS([]);
    } finally {
      this.SET_LOADING(false);
    }
  }
  @Action({ rawError: true })
  public async LoadCortes(idCajero: string) {
    const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSistemaPagos = new ApiSistemaPagos(client);

    try {
      this.SET_LOADING(true);
      const cortes = (await apiSistemaPagos.ConsultarCortesCajaFiltro({ kioskos: [idCajero] })) as CorteCaja[];
      this.SET_CORTES(cortes);
    } catch (e) {
      const err = e as ApiErrorResponse;
      //TODO: Manejar errores
      if (err.statusCode == 404) {
        errorSnackbar.Show(err.apiError.mensajeUsuario);
      }

      this.SET_CORTES([]);
    } finally {
      this.SET_LOADING(false);
    }
  }
  @Action({ rawError: true })
  public async LoadAfiliacionesBanorte() {
    const client = new AxiosHttpClient(getUrl(ApiList.Bancos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiBancos = new ApiBancos(client);

    try {
      this.SET_LOADING(true);
      const afiliaciones = (await apiBancos.GetAfiliaciones()) as Banorte.Payworks2.Afiliacion[];
      this.SET_AFILIACIONES_BANORTE(afiliaciones);
    } catch (e) {
      const err = e as ApiErrorResponse;
      //TODO: Manejar errores
      if (err.statusCode == 404) {
        errorSnackbar.Show(err.apiError.mensajeUsuario);
      }
    } finally {
      this.SET_LOADING(false);
    }
  }
  @Action({ rawError: true })
  public async LoadAfiliacionesConciliablesBanorte() {
    const client = new AxiosHttpClient(getUrl(ApiList.Bancos, this.context.rootGetters.appMode));
    client.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiBancos = new ApiBancos(client);

    try {
      this.SET_LOADING(true);
      const afiliaciones = (await apiBancos.GetAfiliacionesConciliables()) as Banorte.Payworks2.Afiliacion[];
      this.SET_AFILIACIONES_CONCILIABLES_BANORTE(afiliaciones);
    } catch (e) {
      const err = e as ApiErrorResponse;
      //TODO: Manejar errores
      if (err.statusCode == 404) {
        errorSnackbar.Show(err.apiError.mensajeUsuario);
      }
    } finally {
      this.SET_LOADING(false);
    }
  }
}

export default getModule(SelectoresModule);
